import { h, FunctionComponent, createElement } from "preact"
import { DeviceItem, WifiDevice } from "../../../types/Device"
import Plug from "./Plug"
import Slab from "./Slab"
import Amplifier from "./Amplifier"
import Computer from "./Computer"
import Shutter from "./Shutter"
import Fan from "./Fan"
import MotorizedScreen from "./MotorizedScreen"
import Dimmer from "./Dimmer"
import Weather from "./Weather"
import Failure from "./Failure"
import { ErrorBoundary } from "../../ErrorBoundary"
import AirSensor from "./AirSensor"

type IDeviceProps = {
  device: DeviceItem
}

export const Device: FunctionComponent<IDeviceProps> = ({ device }) => {

  let Component: FunctionComponent<{ device: any }> | null = null

  switch (device.id) {
    case "amplifier":
      Component = Amplifier
      break
    case "ventilation":
    case "kitchen-fan":
    case "3dprinter-fan":
      Component = Fan
      break
    case "weather":
    case "weather-mom":
    case "weather-home":
      Component = Weather
      break
    case "air-housekeeper":
      Component = AirSensor
      break
  }

  if (!Component) {
    switch (device.widget) {
      case "state-dimmer":
        Component = Dimmer
        break
      case "state":
        Component = Plug
        break
      case "shutter":
        Component = Shutter
        break
    }
  }

  if (!Component) {
    switch (device.hardware) {
      case "computer":
        Component = Computer
        break
      case "wifi":
        const features = (device as WifiDevice).features
        if (features.includes("fan")) {
          Component = Fan
        } else if (features.includes("motorized-screen")) {
          Component = MotorizedScreen
        }
        break
    }
  }

  if (!Component) {
    console.error("No component found for device", device)
  }

  return createElement(ErrorBoundary,
    { onFailure: (error) => createElement(Failure, { device, error }) },
    createElement(Component || Slab, { device })
  )
}

import { FunctionComponent, h } from "preact"
import { useApi } from "../../../hooks/useAct"
import { AirSensorDevice } from "../../../types/Device"
import styles from "../../styles.scss"
import Slab from "./Slab"
import Flex from "../../ui/Flex"

type IAirSensorProps = {
  device: AirSensorDevice
}

const SensorValue: FunctionComponent<{
  value: number
  label: string
  isError: (value: number) => boolean
  criterium: string
}> = ({ value, label, isError, criterium }) => {

return <Flex horizontalAlign="center" verticalAlign="center" horizontal className={isError(value) ? styles.colorDarkRed : undefined} style={{zoom: .8}}>
  <div style={{fontSize: "200%", lineHeight: 1.1, width: 80, textAlign: "right", paddingRight: 15}}>{value}</div>

  <Flex style={{opacity: .7, letterSpacing: 0.5, fontSize: "80%"}}>
    <span>{label}</span>
    <span style={{fontSize: "70%", opacity: .7}}>&nbsp; ({criterium})</span>
  </Flex>
</Flex>
}


export const AirSensor: FunctionComponent<IAirSensorProps> = ({ device }) => {

  const [act, loading] = useApi(device)

  const toggleState = () => act(`${device.state ? "off" : "on"}`)

  return <Slab
    loading={loading}
    disabled={device.reachable === false}
    device={device}
    props={{
      container: {
        className: device.state ? styles.on : "",
        onClick: toggleState,
      },
    }}
    content={() => <Flex  style={{height: "100%"}} horizontalAlign="center" verticalAlign="center">
      <SensorValue
        value={device.tuyaData.ch2o_value}
        label="CH20"
        criterium="< 10"
        isError={(v) => v >= 10}
      />
      <div style={{marginTop: 10}} />
      <SensorValue
        value={device.tuyaData.voc_value}
        label="VOC"
        criterium="< 50"
        isError={(v) => v >= 50}
      />
      <div style={{marginTop: 10}} />
      <label>Air (balcon)</label>
    </Flex>}
  />
}

export default AirSensor
